export const authorizeUserComponentAccess = (user = {}, workspaceId = "", permissionCode = "", permissionTypes = [], show = false) => {
  if (user) {
    if (user.type === "admin") {
      return true;
    } else if (user.type === "editor") {
      return false

      /* if (permissionCode && user.userPermissions) {
        if (permissionTypes?.length && workspaceId) {
          const missingPermissions = permissionTypes.filter((permissionType) => {
            if (!user.userPermissions[workspaceId] || !user.userPermissions[workspaceId][`${permissionCode}_${permissionType}`]) {
              return true;
            }
            return false;
          });
          return !missingPermissions.length;
        }
        return user.userPermissions[workspaceId] && (user.userPermissions[workspaceId][`${permissionCode}_create`] || user.userPermissions[workspaceId][`${permissionCode}_update`] || user.userPermissions[workspaceId][`${permissionCode}_delete`]);
      }
      return false; */
    } else {
      return false;
    }
  }
};

export const authorizeUserComponentAccessDuplicate = (user = {}, workspaceId = "", permissionCode = "", permissionTypes = [], show = false) => {
  if (user) {
    if (user.type === "admin") {
      return true;
    } else if (user.type === "editor") {
      if (permissionCode && user.userPermissions) {
        if (permissionTypes?.length && workspaceId) {
          const missingPermissions = permissionTypes.filter((permissionType) => {
            if (!user.userPermissions[workspaceId] || !user.userPermissions[workspaceId][`${permissionCode}_${permissionType}`]) {
              return true;
            }
            return false;
          });
          return !missingPermissions.length;
        }
        return user.userPermissions[workspaceId] && (user.userPermissions[workspaceId][`${permissionCode}_create`] || user.userPermissions[workspaceId][`${permissionCode}_update`] || user.userPermissions[workspaceId][`${permissionCode}_delete`]);
      }
      return false;
    } else {
      return false;
    }
  }
};