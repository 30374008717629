import { useState, useEffect } from "react";
import { KeyIcon, TrashIcon } from "@heroicons/react/24/outline";
import { fetchData } from "src/async/fetch";
import { baseUrl } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import { toast } from "react-toastify";
import { randomHashString } from "src/helpers/randomHashString";
import { classNames } from "src/helpers/classNames";

const WorkspaceIntegrationDevToken = ({ user, workspaceId, integration }) => {
  const [devTokenData, setDevTokenData] = useState(null);
  const [disableDevTokenSubmit, setDisableDevTokenSubmit] = useState(false)

  useEffect(() => {
    const loadDevToken = async () => {
      try {
        const res = await fetchData("GET", `${baseUrl}/api/workspaces/${workspaceId}/integrations/${integration._id}/dev-token`);
        if (res.ok) {
          const data = await res.json();
          if (data.data?._id) {
            setDevTokenData({ _id: data.data._id, value: "", tokenLength: data.data.tokenLength });
          }
        }
      } catch (err) {
        return { status: 400, message: err.message };
      }
    };

    if (integration?._id && workspaceId) {
      loadDevToken();
    }
  }, [integration?._id, workspaceId]);

  const handleDevTokenUpdate = (e) => {
    setDevTokenData((data) => ({ ...data, value: e.target.value }));
  };

  const saveDevTokenChanges = async () => {
    setDisableDevTokenSubmit(true)

    try {
      const res = await fetchData("POST", `${baseUrl}/api/workspaces/${workspaceId}/integrations/${integration._id}/dev-token`, { devToken: devTokenData.value });

      const data = await res.json();

      if (res.ok) {
        if (data.data) {
          setDevTokenData((curData) => ({ ...curData, _id: data?.data?._id }));
        }
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableDevTokenSubmit(false)
    }
  };

  const removeDevToken = async () => {
    try {
      setDevTokenData(null);

      const res = await fetchData("POST", `${baseUrl}/api/workspaces/${workspaceId}/integrations/${integration._id}/dev-token`, { devToken: "" });

      const data = await res.json();
      if (!res.ok) {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="relative">
        {devTokenData == null && authorizeUserComponentAccess(user) && (
          <div className="w-full justify-between flex items-center gap-4">
            <div className="text-base font-medium text-slate-400">Developer Token</div>
            <Button
              version="black"
              className="group !py-1 !pl-[5px] !pr-[10px]"
              onClick={() => setDevTokenData({ value: "" })}>
              <IconsWithPlus
                strokeColor={"stroke-gray-700 group-hover:stroke-highlightColor"}
                item={{ icon: KeyIcon }}
              />
            </Button>
          </div>
        )}
        {devTokenData != null ? (
          <div className="mt-2 w-full h-full relative border border-gray-50 shadow pb-3 pt-1 px-4 rounded-md">
            <div className="relative w-full items-end flex flex-col sm:flex-row gap-x-3 max-w-4xl">
              <>
                <div className="grow">
                  <Input
                    type="password"
                    name="developer-token"
                    label="Developer Token"
                    placeholder={randomHashString(devTokenData?.tokenLength || 0)}
                    value={devTokenData?.value}
                    onChange={handleDevTokenUpdate}
                  />
                </div>
                <div className="h-full flex items-center gap-x-3 mb-[3px]">
                  <button
                    type="button"
                    className={classNames("p-1 hover:bg-gray-100/70 transition-all duration-300 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none border-[2px] border-gray-100 hover:border-gray-200", !disableDevTokenSubmit ? 'cursor-pointer' : 'opacity-30 cursor-not-allowed')}
                    onClick={saveDevTokenChanges}
                    disabled={disableDevTokenSubmit}
                  >
                    <span className="sr-only">Save</span>
                    Save
                  </button>
                  <button
                    type="button"
                    className="p-1 hover:bg-gray-100/70 transition-all duration-300 rounded-md text-gray-400 hover:text-gray-500 border-transparent focus:outline-none border-[2px] hover:border-gray-200"
                    onClick={removeDevToken}>
                    <span className="sr-only">Delete</span>
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </>
            </div>
          </div>
        ) : (
          <div className="w-full space-y-3 mt-2 bg-white border border-slate-100 rounded py-3 px-4">
            <p className="py-1 flex justify-center text-gray-300">None found</p>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkspaceIntegrationDevToken;
