import { useEffect, useState, useRef } from "react";
import { ArrowDownIcon } from "@heroicons/react/24/solid";
import { apiUrl } from "src/config/host";
import { classNames } from "src/helpers/classNames";

const Terms = ({ setIsAgreementScrolled = () => {}, isAgreementScrolled = false }) => {
  let [htmlFileString, setHtmlFileString] = useState("");
  const contentRef = useRef(null); // Reference to the container div

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const element = contentRef.current;
    if (element) {
      const scrollPercentage = (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;
      if (scrollPercentage > 50) {
        setIsAgreementScrolled(true);
        // Optionally, remove the event listener after reaching 50% scroll
        element.removeEventListener("scroll", handleScroll);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(apiUrl + "/uploads/terms_and_conditions.html");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const textData = await response.text();
        setHtmlFileString({ __html: textData });
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Adding the scroll event listener
    const element = contentRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [contentRef, handleScroll]);

  return (
    <>
      <div>
        {htmlFileString && (
          <div
            ref={contentRef}
            className="relative max-h-[calc(100vh-20rem)] overflow-auto scroll-smooth scrollbar pr-4">
            <div className="text-base space-y-4">
              <div className="space-y-3">
                <div dangerouslySetInnerHTML={htmlFileString} />
              </div>
            </div>
          </div>
        )}
        <div className="pt-2 w-full flex items-center justify-center">
          <div className={classNames("text-gray-600 flex items-center justify-center gap-x-3 px-3 py-1 bg-gray-100 rounded-full transition-all duration-50 ", isAgreementScrolled ? "opacity-0" : "opacticy-100")}>
            Scroll to the bottom
            <ArrowDownIcon className="h-5 w-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
