import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { connect } from "react-redux";

const WorkspaceTag = ({ index = -1, tag = "", onDelete = () => {}, me }) => {
  return (
    <div
      key={index}
      className="relative flex items-center justify-between bg-gray-200 px-2 py-1 rounded pr-7">
      <div className="text-xs flex items-center font-semibold text-gray-500 whitespace-nowrap">{tag}</div>
      {me?.type === "admin" && (
        <button
          className="w-4.5 h-4.5 absolute top-auto bottom-auto right-1 flex items-center justify-center transition-colors duration-200 bg-transparent rounded-full hover:bg-gray-300"
          onClick={() => onDelete(index)}>
          <XMarkIcon className="w-4 h-4 text-gray-600" />
        </button>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(WorkspaceTag)