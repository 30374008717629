import React, { useContext, useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Checkbox";
import { RegistrationContext } from "src/context/RegistrationContext";
import { fetchData } from "src/async/fetch";
import { toast } from "react-toastify";
import { apiUrl } from "src/config/host";

const initialErrorState = {
  firstName: null,
  lastName: null,
  password: null,
  email: null,
  verifyEmail: null,
};

const RegistrationStepOne = ({ data, setCurrentStep, initialRetrievalData }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isFieldRequired, setIsFieldRequired] = useState(initialErrorState);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const { userDataState, setUserDataState, retrievalDataState, setRetrievalDataState, isCheckedSameContactState, setIsCheckedSameContactState } = useContext(RegistrationContext);

  const handleNextPage = async () => {
    const isErrorExist = checkIsErrorExists();
    const isValidPass = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}/.test(userDataState?.password);

    if (!isErrorExist) {
      if (!isValidPass) {
        toast.error("Password should be atleast one uppercase, one lowercase, one number, one special character and length should be minimum 8", {
          autoClose: 4000,
        });
        return;
      }
      const isExist = await checkIsEmailExists();
      if (!isExist) {
        setCurrentStep("2");
      }
    }
  };

  // useEffect(() => {
  //   setCurrentStep("3");
  // }, [])

  const checkIsErrorExists = () => {
    if (!userDataState?.firstName.trim() || !userDataState?.firstName.length) {
      setIsFieldRequired((prev) => ({ ...prev, firstName: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, firstName: false }));
    }
    if (!userDataState?.lastName.trim() || !userDataState?.lastName.length) {
      setIsFieldRequired((prev) => ({ ...prev, lastName: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, lastName: false }));
    }
    if (!userDataState?.email.trim() || !userDataState?.email.length) {
      setIsFieldRequired((prev) => ({ ...prev, email: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, email: false }));
    }
    if (!userDataState?.verifyEmail.trim() || !userDataState?.verifyEmail.length) {
      setIsFieldRequired((prev) => ({ ...prev, verifyEmail: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, verifyEmail: false }));
    }
    if (!userDataState?.password.trim() || !userDataState?.password.length) {
      setIsFieldRequired((prev) => ({ ...prev, password: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, password: false }));
    }
    setIsFieldRequired(initialErrorState);
    return false;
  };

  const checkIsEmailExists = async () => {
    try {
      setIsNextLoading(true);
      const resp = await fetchData("POST", `${apiUrl}/email-exist`, {
        email: userDataState?.email,
        verifyEmail: userDataState?.verifyEmail,
      });

      if (resp.ok) {
        return false;
      }
      const res = await resp.json();
      if (res.message) {
        toast.error(res.message);
        return true;
      }
    } catch (error) {
      toast.error("Error while checking email");
      return true;
    } finally {
      setIsNextLoading(false);
    }
  };

  const handleContactOnChange = (key, value) => {
    setUserDataState(key, value);
    if (isCheckedSameContactState) {
      handleAutofillRetrieval(key, value);
    }
  };

  const handleAutofillRetrieval = (key, value) => {
    if (key === "firstName" || key === "lastName" || key === "businessRole" || key === "phoneNumber" || key === "email" || key === "verifyEmail") {
      if (key === "firstName") {
        setRetrievalDataState("firstName", value);
      } else if (key === "lastName") {
        setRetrievalDataState("lastName", value);
      } else if (key === "businessRole") {
        setRetrievalDataState("businessRole", value);
      } else if (key === "phoneNumber") {
        setRetrievalDataState("phoneNumber", value);
      } else if (key === "email") {
        setRetrievalDataState("email", value);
      } else if (key === "verifyEmail") {
        setRetrievalDataState("verifyEmail", value);
      }
    }
  };

  // useEffect(() => {
  //   if (isCheckedSameContactState === false) {
  //     setIsCheckedSameContactState(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isCheckedSameContactState]);

  const handleIsRetrivalSame = (isTrue) => {
    const { password, ...newUserData } = userDataState;
    if (isTrue) {
      Object.keys(newUserData).forEach((element) => {
        setRetrievalDataState(element, newUserData[element]);
      });
    } else {
      Object.keys(initialRetrievalData).forEach((element) => {
        setRetrievalDataState(element, initialRetrievalData[element]);
      });
    }
    setIsChecked(isTrue);
  };

  useEffect(() => {
    handleIsRetrivalSame(isCheckedSameContactState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckedSameContactState]);

  useEffect(() => {
    const isDisable = !(userDataState?.firstName.length && userDataState?.lastName.length && userDataState?.email.length && userDataState?.verifyEmail.length && userDataState?.password.length);
    setIsNextDisabled(isDisable);
  }, [userDataState?.firstName.length, userDataState?.lastName.length, userDataState?.email.length, userDataState?.verifyEmail.length, userDataState?.password.length]);

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-1">
            <h1 className="text-3xl font-bold text-highlightColor">Contact information</h1>
            <div className="text-lg text-black">Please input your contact information to get started.</div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="w-full">
              <Input
                type="text"
                autoComplete="new-first-name"
                error={isFieldRequired?.firstName}
                name="first-name"
                label="First Name"
                value={userDataState?.firstName}
                onChange={(e) => {
                  if (e.target?.value.trim().length <= 0 && e.target?.value === " ") {
                    return;
                  }
                  handleContactOnChange("firstName", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="text"
                autoComplete="new-last-name"
                error={isFieldRequired?.lastName}
                name="last-name"
                label="Last Name"
                value={userDataState?.lastName}
                onChange={(e) => {
                  if (e.target?.value.trim().length <= 0 && e.target?.value === " ") {
                    return;
                  }
                  handleContactOnChange("lastName", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="text"
                autoComplete="new-business-role"
                name="business-role"
                label="Job Title"
                value={userDataState?.businessRole}
                onChange={(e) => {
                  handleContactOnChange("businessRole", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="tel"
                autoComplete="new-phone-number"
                name="phone-number"
                label="Phone Number"
                value={userDataState?.phoneNumber}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  handleContactOnChange("phoneNumber", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="email"
                autoComplete="new-email"
                error={isFieldRequired?.email}
                name="email"
                label="Email"
                value={userDataState?.email}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  handleContactOnChange("email", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="email"
                autoComplete="new-verify-email"
                error={isFieldRequired?.verifyEmail}
                name="verify-email"
                label="Verify Email"
                value={userDataState?.verifyEmail}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  handleContactOnChange("verifyEmail", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <div className="w-full min-w-[200px]">
                <Input
                  autoComplete="new-password"
                  error={isFieldRequired?.password}
                  type="password"
                  name="password"
                  label="Password"
                  value={userDataState?.password}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    handleContactOnChange("password", e.target.value);
                  }}
                />
              </div>
              <p className="text-xs text-gray-400">Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a special character.</p>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="space-y-1">
            <h4 className="text-xl font-bold text-highlightColor">For data retrieval, who should we contact?</h4>
            <Checkbox
              isChecked={isCheckedSameContactState}
              onChange={setIsCheckedSameContactState}
              checkboxLabel={"Check here if same as organization contact"}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="w-full">
              <Input
                type="text"
                autoComplete="new-first-name"
                name="first-name"
                label="First Name"
                disabled={isChecked}
                value={retrievalDataState?.firstName}
                onChange={(e) => {
                  setRetrievalDataState("firstName", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="text"
                autoComplete="new-last-name"
                name="last-name"
                label="Last Name"
                disabled={isChecked}
                value={retrievalDataState?.lastName}
                onChange={(e) => {
                  setRetrievalDataState("lastName", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="text"
                autoComplete="new-business-role"
                name="business-role"
                label="Your Business Role"
                disabled={isChecked}
                value={retrievalDataState?.businessRole}
                onChange={(e) => {
                  setRetrievalDataState("businessRole", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="tel"
                autoComplete="new-phone-number"
                name="phone-number"
                label="Phone Number"
                disabled={isChecked}
                value={retrievalDataState?.phoneNumber}
                onChange={(e) => {
                  setRetrievalDataState("phoneNumber", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="email"
                autoComplete="new-email"
                name="email"
                label="Email"
                disabled={isChecked}
                value={retrievalDataState?.email}
                onChange={(e) => {
                  setRetrievalDataState("email", e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                type="email"
                autoComplete="new-verify-email"
                name="verify-email"
                label="Verify Email"
                disabled={isChecked}
                value={retrievalDataState?.verifyEmail}
                onChange={(e) => {
                  setRetrievalDataState("verifyEmail", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between border-t border-slate-200 pt-5">
          <div className="ml-auto">
            <Button
              width="w-full"
              onClick={() => handleNextPage()}
              disabled={isNextLoading || isNextDisabled}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationStepOne;
