import { connect } from "react-redux";
import Accordion from "src/components/Shared/Accordions/Accordion";
import WorkspaceIntegrationClients from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClients";
import WorkspaceIntegrationFilters from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFilters";
import WorkspaceIntegrationDevToken from "./WorkspaceIntegrationDevToken";

const WorkspaceIntegrationItem = ({ user, is_global = false, workspaceId = "", integration = {}, workspaceDetails = {} }) => {
  return (
    <Accordion
      startOpen={true}
      headerContent={() => (
        <div className="flex items-center">
          <div className="w-12 h-12 min-w-[48px] min-h-[48px] overflow-hidden">
            <img
              src={"https://www.domo.com/assets/images/domo-logos/domo-svg.svg"}
              alt={"Domo"}
            />
          </div>
          <div className="w-full px-4">
            <div className="text-slate-400">{integration.name}</div>
          </div>
        </div>
      )}>
      <div className="relative p-4 space-y-6">
        <WorkspaceIntegrationClients
          integration={integration}
          workspaceId={workspaceId}
          workspaceDetails={workspaceDetails}
          is_global={is_global}
        />
        {workspaceId && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && (
          <WorkspaceIntegrationFilters
            integration={integration}
            workspaceId={workspaceId}
          />
        )}
        {
          workspaceId &&
          <WorkspaceIntegrationDevToken 
            user={user}
            integration={integration}
            workspaceId={workspaceId}
          />
        }
      </div>
    </Accordion>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, {})(WorkspaceIntegrationItem);
