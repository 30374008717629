import React, { useContext } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { RegistrationContext } from "src/context/RegistrationContext";

const HrisSystemOne = () => {
  const { HRISSystemDataState, setHRISSystemDataState } = useContext(RegistrationContext);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="w-full">
        <Input
          type="text"
          autoComplete="new-others-hris-system"
          name="others-hris-system"
          label="API Key"
          value={HRISSystemDataState.apiKey}
          onChange={(e) => setHRISSystemDataState("apiKey", e.target.value)}
        />
      </div>

      <div className="w-full">
        <Input
          type="text"
          autoComplete="new-others-hris-system"
          name="others-hris-system"
          label="Company Domain"
          value={HRISSystemDataState.companyDomain}
          onChange={(e) => setHRISSystemDataState("companyDomain", e.target.value)}
        />
      </div>
    </div>
  );
};

export default HrisSystemOne;
