import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import { getDomoPageDetails } from "src/actions/page";
import ReactHtmlParser from "react-html-parser";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import LandingPage from "./landing-page";

const PortalWorkspace = ({ user }) => {
  const { pathname } = useLocation();
  const { workspaceId, pageId, linkId } = useParams();
  useEffect(() => {
    setSrcDoc("");
    setNoPage(true);
    setLoadingPage(true);

    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/gh/clearsquare-dev/tailwind-cdn@main/style.min.js";
    script.async = true;
    script.id = "tailwindScript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [pathname]);

  const [srcDoc, setSrcDoc] = useState("");
  const [noPage, setNoPage] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [navigationBlocks, setNavigationBlocks] = useState([]);
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    getPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, user?._id]);

  const getPage = async () => {
    let res = await getDomoPageDetails({ userId: user?._id, pageId: pageId, workspaceId: workspaceId, linkId: linkId });
    setLoadingPage(false);
    if (res.status === 400) {
      setNoPage(true);
      return;
    }
    setNoPage(false);
    setPageName(res.page_name);
    if (res.page_type === "CUSTOM_HTML") {
      setSrcDoc(res.custom_html);
      setNavigationBlocks([])
    } else if (res.page_type === "EXTERNAL_LINK") {
      window.open(res.page_link, (res.page_link_newtab) ? "_blank": "_self")
    } else if (res.page_type === "LANDING_PAGE") {
      setNavigationBlocks(res.navigation_blocks);
      setSrcDoc("")
    } else if (res.page_type === "JWT_FULL_EMBED") {
      let { embedUrl } = res;
      let htmlIframe = `<iframe id="iframe" src="${embedUrl}" width="100%" height="100%" marginheight="0" marginwidth="0" frameborder="0"></iframe>`;
      setSrcDoc(htmlIframe);
      setNavigationBlocks([])
    } else {
      let { embedToken, embedUrl } = res;

      let htmlIframe = `<iframe id="iframe" title="page-embed" src="" width="100%" height="100%" marginHeight="0" marginWidth="0" frameBorder="0" srcDoc='<html>
      <body>
        <form id="form" action="${embedUrl}" method="post">
          <input type="hidden" name="embedToken" value="${embedToken}" />
        </form>
      </body>
      <script>document.getElementById("form").submit();</script>
    </html>'></iframe>`;

      setSrcDoc(htmlIframe);
      setNavigationBlocks([])
    }
  };

  return (
    <div id="pageContent" className="h-full w-full flex flex-col">
      <div className="h-full w-full lg:w-[1250px] mx-auto">{!loadingPage && !noPage && (srcDoc || navigationBlocks.length > 0) ? ((srcDoc)?ReactHtmlParser(srcDoc):<LandingPage from={'portal'} workspaceId={workspaceId} pageId={pageId} pageName={pageName} navigationBlocks={navigationBlocks} />) : <div className="w-full h-full flex justify-center mt-20">{loadingPage ? <Preloader /> : !loadingPage && noPage && <NoData />}</div>}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(PortalWorkspace);
