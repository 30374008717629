import { useContext, useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import { RegistrationContext } from "src/context/RegistrationContext";
import UploadImage from "src/components/Shared/Forms/Inputs/UploadImage";

import industryGroup from "src/assets/json/naics";

const initialErrorState = {
  name: null,
};
const RegistrationStepTwo = ({ data, setCurrentStep }) => {
  // const [companyOwnership, setCompanyOwnership] = useState("");
  // const [companySize, setCompanySize] = useState("");
  // const [companyIndustry, setCompanyIndustry] = useState("");
  const [isFieldRequired, setIsFieldRequired] = useState(initialErrorState);
  // const [isNextEnable, setIsNextEnable] = useState(false)

  const { companyDataState, setCompanyDataState, industryArrState, setindustryArrState, industryGroupState, setIndustryGroupState, industryState, setIndustryState, subIndustryState, setSubIndustryState, subSubIndustryState, setSubSubIndustryState, currentStepState } =
    useContext(RegistrationContext);

  const handleOnNext = () => {
    const isErrorExists = fieldErrorExists();
    if (!isErrorExists) {
      setCurrentStep("3");
    }
  };

  const fieldErrorExists = () => {
    if (!companyDataState?.name.length) {
      setIsFieldRequired((prev) => ({ ...prev, name: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, name: false }));
    }
    setIsFieldRequired(initialErrorState);
    return false;
  };

  const handleDynamicOnChangeDropdown = (key, event) => {
    setCompanyDataState(key, event);
    if (key === "industryGroup") {
      const industryListArr = [];
      const subIndustryListArr = [];
      const subSubIndustryListArr = [];
      const industryList = industryArrState.filter((item) => item.code === event.code)[0]?.industries;
      if (industryList && industryList.length) {
        industryList.forEach((item) => {
          industryListArr.push({ _id: item.code, code: item.code, name: item.title });
        });
      }
      setIndustryState(industryListArr);
      setSubIndustryState(subIndustryListArr);
      setSubSubIndustryState(subSubIndustryListArr);
      setCompanyDataState("industry", "");
      setCompanyDataState("subIndustryGroup", "");
      setCompanyDataState("subSubIndustryGroup", "");
    } else if (key === "industry") {
      const subIndustryListArr = [];
      const subSubIndustryListArr = [];
      const industryList = industryArrState.filter((item) => item.code === companyDataState?.industryGroup.code)[0]?.industries;
      const subIndustryList = industryList.filter((item) => item.code === event.code)[0]?.subIndustries;
      if (subIndustryList && subIndustryList.length) {
        subIndustryList.forEach((item) => {
          subIndustryListArr.push({ _id: item.code, code: item.code, name: item.title });
        });
      }
      setSubIndustryState(subIndustryListArr);
      setSubSubIndustryState(subSubIndustryListArr);
      setCompanyDataState("subIndustryGroup", "");
      setCompanyDataState("subSubIndustryGroup", "");
    } else if (key === "subIndustryGroup") {
      const subSubIndustryListArr = [];
      const industryList = industryArrState.filter((item) => item.code === companyDataState?.industryGroup.code)[0]?.industries;
      const subIndustryList = industryList.filter((item) => item.code === event.code)[0]?.subIndustries;
      const subSubIndustryList = subIndustryList?.filter((item) => item.code === event.code)[0]?.subSubIndustries;
      if (subSubIndustryList && subSubIndustryList.length) {
        subSubIndustryList.forEach((item) => {
          subSubIndustryListArr.push({ _id: item.code, code: item.code, name: item.title });
        });
      }
      setSubSubIndustryState(subSubIndustryListArr);
      setCompanyDataState("subSubIndustryGroup", "");
    }
  };

  useEffect(() => {
    if (industryGroup && industryGroup.length) {
      const industryGroupArr = [];
      industryGroup.forEach((item) => {
        industryGroupArr.push({ _id: item.code, code: item.code, name: item.title });
      });
      setIndustryGroupState(industryGroupArr);
      setindustryArrState(industryGroup);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-1">
            <h1 className="text-3xl font-bold text-highlightColor">Add organization information</h1>
            {/* <div className="text-lg text-black">Please input your organization information to get started.</div> */}
          </div>
          <div className="grid gap-y-4 w-full">
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full">
                <Input
                  type="text"
                  autoComplete="new-company-name"
                  error={isFieldRequired?.name}
                  name="company-name"
                  label="Organization Name"
                  value={companyDataState?.name}
                  onChange={(e) => {
                    if (e.target?.value.trim().length <= 0 && e.target?.value === " ") {
                      return;
                    }
                    setCompanyDataState("name", e.target.value);
                  }}
                />
              </div>
              <div className="w-full">
                <Select
                  label={"Organization Ownership"}
                  options={[
                    { _id: 1, name: "Privately Owned" },
                    { _id: 2, name: "Publicly Traded" },
                    { _id: 3, name: "Not For Profit" },
                    { _id: 4, name: "Government" },
                  ]}
                  selected={companyDataState?.ownership !== "" ? companyDataState?.ownership : { _id: -1, name: "Select Ownership Type" }}
                  setSelected={(e) => {
                    setCompanyDataState("ownership", e);
                  }}
                />
              </div>
              <div className="w-full">
                <Select
                  label={"Organization Size"}
                  options={[
                    { _id: 1, name: "< 100" },
                    { _id: 2, name: "100 - 499" },
                    { _id: 3, name: "500 - 999" },
                    { _id: 4, name: "1000 - 4999" },
                    { _id: 5, name: "5000+" },
                  ]}
                  selected={companyDataState?.size !== "" ? companyDataState.size : { _id: -1, name: "Select number of employees" }}
                  setSelected={(e) => {
                    setCompanyDataState("size", e);
                  }}
                />
                <div className="text-xs mt-1 text-slate-400 tracking-wider">Minimum of 100 employees is recommended.</div>
              </div>
            </div>

            <div className="grid gap-y-4 w-full my-2">
              <div className="w-full">
                <Select
                  disabled={!industryGroupState.length}
                  label={"Industry Group"}
                  options={!industryGroupState?.length ? [{ _id: -2, name: "Please wait until list fetch" }] : industryGroupState.sort((a, b) => a.name.localeCompare(b.name))}
                  selected={companyDataState?.industryGroup !== "" ? companyDataState.industryGroup : { _id: -1, name: "Select Industry Group" }}
                  setSelected={(e) => {
                    handleDynamicOnChangeDropdown("industryGroup", e);
                  }}
                />
              </div>
              <div className="w-full">
                <Select
                  disabled={!industryState.length || !companyDataState.industryGroup}
                  label={"Industry"}
                  options={!industryState.length ? [{ _id: -2, name: "Please select options from industry group" }] : industryState.sort((a, b) => a.name.localeCompare(b.name))}
                  selected={companyDataState?.industry !== "" ? companyDataState.industry : { _id: -1, name: "Select Industry" }}
                  setSelected={(e) => {
                    handleDynamicOnChangeDropdown("industry", e);
                  }}
                />
              </div>
              <div className="w-full">
                <Select
                  disabled={!subIndustryState.length || !companyDataState.industry}
                  label={"Sub-Industry"}
                  options={!subIndustryState.length ? [{ _id: -2, name: "Please select options from industry" }] : subIndustryState.sort((a, b) => a.name.localeCompare(b.name))}
                  selected={companyDataState?.subIndustryGroup !== "" ? companyDataState.subIndustryGroup : { _id: -1, name: "Select Sub Industry" }}
                  setSelected={(e) => {
                    handleDynamicOnChangeDropdown("subIndustryGroup", e);
                  }}
                />
              </div>
            </div>

            {/* {subSubIndustryState.length > 0 && (
                <>
                  <div className="w-full">
                    2<div className="text-lg text-black">Sub-Industry (optional)</div>
                    <div className="text-sm text-slate-500 w-full undefined">Adding Sub-Industries will give you the most relevant ParityINDEX data. (benchmarking)</div>
                  </div>
                  <div className="w-full">
                    <Select
                      disabled={!subIndustryState.length}
                      // label={"Sub-Industry (optional)"}
                      options={!subIndustryState.length ? [{ _id: -2, name: "Please select options from industry" }] : subIndustryState}
                      selected={companyDataState?.subIndustryGroup !== "" ? companyDataState.subIndustryGroup : { _id: -1, name: "Select Sub Industry" }}
                      setSelected={(e) => {
                        handleDynamicOnChangeDropdown("subSubIndustryGroup", e);
                      }}
                    />
                  </div>
                </>
              )} */}
          </div>
          <div>
            <h4 className="text-xl font-bold text-highlightColor">Add your organization's logo</h4>
            <div className="text-sm text-slate-500 w-full undefined">Your logo will look best if it's uploaded in a .png format with a transparent background.</div>
            <UploadImage
              fileValue={companyDataState?.companyLogo}
              onFileChange={(e) => {
                setCompanyDataState("companyLogo", e);
              }}
              maxSizeInKB={2000}
            />
          </div>
        </div>

        <div className="flex justify-between border-t border-slate-200 pt-5">
          <div className="mr-auto">
            <Button
              width="w-full"
              onClick={() => setCurrentStep("1")}>
              Back
            </Button>
          </div>
          <div className="ml-auto">
            <Button
              width="w-full"
              disabled={!(currentStepState === "2" && companyDataState?.name && companyDataState.name.length && companyDataState?.ownership && companyDataState?.size && companyDataState?.industryGroup && companyDataState?.industry)}
              onClick={() => handleOnNext()}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationStepTwo;
