import { Bars3Icon, PlusCircleIcon, PlusIcon, ArrowPathIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import FormInput from "../Shared/Forms/Inputs/Input";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import { classNames } from "src/helpers/classNames";
import * as Yup from "yup";
import DisplayPageCondition from "./DisplayPageCondition";
import Button from "../Shared/Buttons/Button";
import { toast } from "react-toastify";

const DisplayPageForm = ({ pageOptions = [], operatorOptions = [], permission = {}, setPermission = () => {}, id, removePermission = () => {}, trustedAttributeArr, valueType, draggableProps, dragHandleProps, innerRef, workspaceDetails, showRefreshUrls = false, onRefreshUrl = () => {}, disableRefreshUrl = false, ...props }) => {
  // const embedTypes = {
  //   DOMO: "DOMO Page",
  //   EMBED_CARD: "DOMO Card",
  //   CUSTOM_HTML: "Custom HTML",
  //   LANDING_PAGE: "Landing Page",
  // };

  const [addWhere, setAddWhere] = useState(false);
  const [pageType, setPageType] = useState("");
  const [pageCopied, setPageCopied] = useState(false);

  useEffect(() => {
    if (pageCopied) {
      setTimeout(() => {
        setPageCopied(false);
      }, 6000);
    }
  }, [pageCopied]);

  useEffect(() => {
    let index = pageOptions.findIndex((op) => op._id === permission?.page_id);
    if (index !== -1) {
      setPageType(pageOptions[index].page_type);
      if (pageOptions[index].page_type === "CUSTOM_HTML") {
        setPermission({
          ...permission,
          filters: [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOptions, permission?.page_id]);

  useEffect(() => {
    if (permission?.datasource_id) {
      setAddWhere(true);
    }
  }, [permission?.datasource_id]);

  const handleConditionAdd = () => {
    setPermission({
      ...permission,
      filters: [
        ...permission.filters,
        {
          column_name: "",
          operator_id: null,
          value_type: "value",
          column_value: "",
          trusted_attribute: "",
        },
      ],
    });
  };

  const pageDetails = pageOptions.find((pageOption) => pageOption._id === permission.page_id);

  const onCopy = async (text = "") => {
    toast.success("Page URL Successfully copied.");
    await navigator.clipboard.writeText(text);
  };

  return (
    <div
      className={"relative w-full max-w-6xl " + (props?.className ? props?.className : "")}
      ref={innerRef}
      {...draggableProps}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          page_id: permission?.page_id || "",
          datasource_id: permission?.datasource_id || "",
        }}
        validationSchema={Yup.object({
          page_id: Yup.string().required("Required"),
        })}>
        {({ values, errors, touched }) => {
          const handleOnChange = (e) => {
            const page = pageOptions.find((op) => op._id === e.target.value);
            if (page) {
              setPageType(page.page_type);
            }

            setPermission({
              ...permission,
              [e.target.name]: e.target.value,
            });
          };
          return (
            <Form
              onChange={handleOnChange}
              className="relative w-full max-w-6xl bg-white rounded">
              <div className={`relative flex border border-gray-200 rounded-md w-full  ${pageType === "DOMO" ? "rounded-br-none" : ""}`}>
                <button
                  {...dragHandleProps}
                  type="button"
                  className="w-10 min-w-[40px] flex items-center justify-center border-r border-gray-200 text-gray-700 outline-none ring-0 bg-gray-100 cursor-move">
                  <Bars3Icon className="w-5 h-5 stroke-2" />
                </button>
                <div className="w-full flex items-center justify-between py-3 px-4">
                  <div className="w-64">
                    <Input
                      name="page_id"
                      as="select"
                      value={values.page_id}>
                      <option value="">Select</option>
                      {pageOptions.map((pageOption) => (
                        <option
                          key={pageOption._id}
                          value={pageOption._id}>
                          {pageOption.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div className="flex items-center">
                    {pageDetails?.page_type === "DOMO" && (
                      <div className="px-3">
                        <Button
                          type={"button"}
                          className="text-xs !p-0 !bg-transparent text-slate-600 border-none !shadow-none focus:!ring-0"
                          onClick={() => setAddWhere((current) => !current)}>
                          <PlusCircleIcon className="w-4 h-4" />
                          Add Where
                        </Button>
                      </div>
                    )}
                    {workspaceDetails?.layout_type === "CUSTOM_NAVIGATION" && permission.page_id && (
                      <>
                        <button
                          data-tooltip-id={`${props?.id}-custom-link`}
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(`${props?.site?.site_domain}/workspaces/${props?.workspaceId}/page/${permission?.page_id}`);
                            setPageCopied(true);
                          }}
                          className="hidden sm:flex w-8 h-8 items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                          <div className="relative">
                            <Square2StackIcon className={classNames("w-6 h-6 transition-all duration-150", pageCopied ? "text-green-600/70" : "")} />
                            <div className={classNames("bg-white absolute -top-2 -right-2 rounded-full opacity-0 transition-all duration-150", pageCopied ? "opacity-100" : "")}>
                              <CheckCircleIcon className="w-5 h-5 text-green-600/50" />
                            </div>
                          </div>
                        </button>
                        <ReactTooltip
                          id={`${props?.id}-custom-link`}
                          delayShow={200}
                          positionStrategy="fixed"
                          className="opacity-100 bg-gray-700 rounded px-2 py-2">
                          <div className="font-normal leading-[10px]">Copy page link.</div>
                        </ReactTooltip>
                      </>
                    )}
                    <button
                      type="button"
                      onClick={removePermission}
                      className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                      <TrashIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
              {addWhere && (
                <div className="relative ml-[40px] border border-t-0 grid gap-y-2 border-gray-200 py-2 px-4 bg-white">
                  <div className="w-full flex items-center gap-4">
                    <div className="text-sm text-slate-800">Where</div>
                    <div className="relative w-80 py-2">
                      <Input
                        label=""
                        name="datasource_id"
                        placeholder="Datasource ID"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>

      {pageType === "DOMO" && (
        <div className="relative ml-[40px] border border-t-0 grid gap-y-2 border-gray-200 py-4 px-4 rounded-b-md bg-white border-b-rounded">
          {permission?.filters?.length > 0 &&
            permission.filters.map((condition, index) => (
              <DisplayPageCondition
                key={id + "_" + index}
                condition={condition}
                setCondition={(e) =>
                  setPermission({
                    ...permission,
                    filters: permission.filters.map((p, i) => {
                      if (i === index) {
                        return e;
                      } else {
                        return p;
                      }
                    }),
                  })
                }
                operatorOptions={operatorOptions}
                removeCondition={(e) =>
                  setPermission({
                    ...permission,
                    filters: permission.filters.filter((p, i) => i !== index),
                  })
                }
                trustedAttributeArr={trustedAttributeArr}
                valueType={valueType}
              />
            ))}
          <div className="relative flex justify-between items-center">
            <button
              onClick={handleConditionAdd}
              type="button"
              className="flex items-center justify-center h-10 py-0 px-0 text-base font-medium text-highlightColor gap-2">
              <PlusIcon className="w-4 h-4 stroke-2" /> Add Condition
            </button>
            {
              showRefreshUrls &&
              <button className={classNames("flex items-center justify-center h-10 py-0 px-0 text-base font-medium text-textLightColor gap-2", !disableRefreshUrl ? '' : 'opacity-40 cursor-not-allowed')}
                onClick={(e) => onRefreshUrl(permission?.page_id)}
                disabled={disableRefreshUrl}
              >
                Refresh URLs
                <ArrowPathIcon className={classNames("w-4 h-4 stroke-2", !disableRefreshUrl ? '' : 'opacity-40 animate-spin')} />
              </button>
            }
          </div>
          {pageDetails &&
            Array.isArray(pageDetails.nested_pages) &&
            pageDetails.nested_pages.length > 0 &&
            pageDetails.nested_pages.map((nestedPage) => (
              <div className="flex items-center justify-between py-2 px-2 border border-slate-200 rounded-lg">
                <select
                  className="w-[30%] h-10 block rounded-md border border-gray-300 py-2 px-4 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm"
                  disabled
                  value={nestedPage._id}>
                  {pageDetails.nested_pages.map((option) => (
                    <option
                      key={option._id}
                      value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <Button
                  type={"button"}
                  className="text-sm px-0 !bg-transparent text-slate-600 border-none !shadow-none focus:!ring-0"
                  onClick={() => onCopy(`${props?.site?.site_domain}/embed/items/${props.workspaceId}/${nestedPage._id}`)}>
                  <Square2StackIcon className="w-5 h-5" />
                  Copy URL
                </Button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(DisplayPageForm);
