import React, { useContext } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { RegistrationContext } from "src/context/RegistrationContext";

const HrisSystemTwo = () => {
  const { HRISSystemDataState, setHRISSystemDataState } = useContext(RegistrationContext);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="w-full">
        <Input
          type="text"
          autoComplete="new-others-hris-system"
          name="others-hris-system"
          label="Username"
          value={HRISSystemDataState.userName}
          onChange={(e) => setHRISSystemDataState("userName", e.target.value)}
        />
      </div>
      <div className="w-full">
        <Input
          type="text"
          autoComplete="new-others-hris-system"
          name="others-hris-system"
          label="Password"
          value={HRISSystemDataState.password}
          onChange={(e) => setHRISSystemDataState("password", e.target.value)}
        />
      </div>
      <div className="w-full">
        <Input
          type="text"
          autoComplete="new-others-hris-system"
          name="others-hris-system"
          label="API URL"
          value={HRISSystemDataState.APIURL}
          onChange={(e) => setHRISSystemDataState("APIURL", e.target.value)}
        />
      </div>
    </div>
  );
};

export default HrisSystemTwo;
