import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { toast } from "react-toastify";
import { manageSiteSettings, getSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import EditOnHoverInput from "src/components/Shared/Forms/Inputs/EditOnHoverInput";
import { H3 } from "src/components/Shared/Text/Headers";
import Section from "src/components/Shared/Containers/Section";
import { toast } from "react-toastify";
import { noFavicon } from "src/config/host"

const SiteThemeAndStyles = ({ ...props }) => {
  const [siteName, setSiteName] = useState(props?.site.site_name || "");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [updatedSite, setUpdatedSite] = useState({});

  const getSiteData = () => {
    return {
      _id: props.site._id,
      style_key: "workspace_id",
      name: props.site.name,
      image_logo: props.site.image_logo || '',
      image_favicon: props.site.image_favicon || noFavicon,
      top_bar_color: props.site?.top_bar_color || "#FFFFFF",
      top_bar_text_color: props.site?.top_bar_text_color || "#FFFFFF",
      side_bar_color: props.site?.side_bar_color || "#FFFFFF",
      side_bar_text_color: props.site?.side_bar_text_color || "#FFFFFF",
      side_bar_shade_color: props.site?.side_bar_shade_color || "#FFFFFF",
      left_nav_active_text_color: props.site?.left_nav_active_text_color || "#FFFFFF",
      highlight_color: props.site?.highlight_color || "#FFFFFF",
      background_color: props.site?.background_color || "#FFFFFF",
      sso_button_color: props.site.sso_button_color,
      sso_button_text_color: props.site.sso_button_text_color,
    };
  };

  const handleStylesUpdate = async (body) => {
    setButtonLoader(true);
    if (body.image_favicon !== props?.site.image_favicon) {
      await apiRequest("DELETE", `/site-settings/images/image_favicon`);
    }
    if (body.image_logo !== props?.site.image_logo) {
      await apiRequest("DELETE", `/site-settings/images/image_logo`);
    }

    try {
      // await apiRequest("PUT", `/site-settings/styles`, { body: { ...body, site_name: siteName } });
      const message = await props.manageSiteSettings({ ...body, site_name: siteName });
      await props.getSiteSettings()
      setTimeout(() => {
        toast.success(message);
        setButtonLoader(false);
      }, 300);
    } catch (error) {
      setTimeout(() => {
        toast.error(error.message);
        setButtonLoader(false);
      }, 300);
    } finally {
      setButtonIsDisabled(true)
    }
  };

  useEffect(() => {
    if (props.site?._id) {
      setUpdatedSite(getSiteData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.site._id]);

  useEffect(() => {
    setSiteName(props?.site.site_name)
  }, [props?.site.site_name])

  const handleStylesDataChange = (keyValue = {}) => {
    setButtonIsDisabled(false)
    setUpdatedSite(
      updatedSite => ({ ...updatedSite, ...keyValue })
    );
  };


  return (
    <Section>
      <div className="w-full">
        <div className="mb-6 flex w-full items-center justify-between">
          <H3 caption="White-label the customer experience with customize colors and logos.">Choose your colors</H3>
        </div>
        <StylesBuilder
          data={{ ...props?.site, name: props?.site.site_name }}
          propsButtonOption={
            {
              buttonLoader: buttonLoader,
              buttonIsDisabled: buttonIsDisabled
            }
          }
          setButtonIsDisabled={setButtonIsDisabled}
          onSubmit={handleStylesUpdate}
          handleBrowserTabText={
            <EditOnHoverInput
              className="max-w-[120px] text-gray-700 font-medium"
              value={siteName}
              onChange={(e) => {
                setSiteName(e.target.value)
                setButtonIsDisabled(false)
              }}
            />
          }
          title="Styles & Text"
          secondaryTitle="Select the perfect set of colors and styles along with a logo and favicon."
          updatedSite={updatedSite}
          setUpdatedSite={setUpdatedSite}
          getSiteData={getSiteData}
          handleStylesDataChange={handleStylesDataChange}
        />
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, getSiteSettings })(SiteThemeAndStyles);
