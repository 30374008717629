import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import { useContext, useEffect, useState } from "react";
import { RegistrationContext } from "src/context/RegistrationContext";

const initialErrorState = {
  totalMembers: null,
};

const RegistrationStepThree = ({ data, setCurrentStep }) => {
  const { directorsCompositionDataState, setDirectorsCompositionDataState, currentStepState } = useContext(RegistrationContext);
  const [isFieldRequired, setIsFieldRequired] = useState(initialErrorState);

  const [hasBoard, setHasBoard] = useState("yes");
  const [hasIncorrectTotals, setHasIncorrectTotals] = useState("");

  useEffect(() => {
    if (hasBoard !== null) {
      if (hasBoard === "no") {
        setDirectorsCompositionDataState("totalMembers", "");
        setDirectorsCompositionDataState("femaleBoardMembers", "");
        setDirectorsCompositionDataState("membersColor", "");
        setDirectorsCompositionDataState("femaleBoardMembersColor", "");

        setIsFieldRequired((prev) => ({ ...prev, totalMembers: false }));
      } else {
        setIsFieldRequired((prev) => ({ ...prev, totalMembers: true }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBoard]);

  const handleOnNext = () => {
    setHasIncorrectTotals("");
    let { totalMembers, femaleBoardMembers, membersColor, femaleBoardMembersColor } = directorsCompositionDataState;
    const isErrorExists = fieldErrorExists();
    if (!isErrorExists) {
      if (totalMembers >= femaleBoardMembers && totalMembers >= membersColor && totalMembers >= femaleBoardMembersColor) {
        data.onClick();
      } else {
        fieldTotalErrors({ totalMembers, femaleBoardMembers, membersColor, femaleBoardMembersColor });
      }
    }
  };

  const fieldTotalErrors = ({ totalMembers, femaleBoardMembers, membersColor, femaleBoardMembersColor }) => {
    if (totalMembers < 1) {
      setHasIncorrectTotals("You have selected that you have a board, but have not entered a positive count for board members.");
      return;
    }
    let errorMessage = "*It appears you may have entered more ";
    if (totalMembers < femaleBoardMembers) {
      errorMessage += "female board members";
    } else if (totalMembers < membersColor) {
      errorMessage += "members of color";
    } else if (totalMembers < femaleBoardMembersColor) {
      errorMessage += "female board members of color";
    } else if (femaleBoardMembers < femaleBoardMembersColor) {
      errorMessage += "female board members of color than female board members.";
      setHasIncorrectTotals(errorMessage);
      return;
    } else if (membersColor < femaleBoardMembersColor) {
      errorMessage += "female board members of color than members of color.";
      setHasIncorrectTotals(errorMessage);
      return;
    } else {
      setHasIncorrectTotals("");
      return;
    }
    errorMessage += " than your listed board total.";
    setHasIncorrectTotals(errorMessage);
  };

  const checkTotals = (e, type) => {
    let number = +e.target.value;
    if (number >= 0 && /^([0-9]{0,7})$/.test(e.target.value) && number < 1000000) {
      let { totalMembers, femaleBoardMembers, membersColor, femaleBoardMembersColor } = directorsCompositionDataState;
      let checkValues = { totalMembers, femaleBoardMembers, membersColor, femaleBoardMembersColor };
      checkValues = { ...checkValues, [type]: number };
      fieldTotalErrors(checkValues);
      setDirectorsCompositionDataState(type, number);
    } else {
      setDirectorsCompositionDataState(type, 0);
    }
  };

  const fieldErrorExists = () => {
    if (hasBoard === "no") {
      return false;
    }
    if (!directorsCompositionDataState?.totalMembers) {
      setIsFieldRequired((prev) => ({ ...prev, totalMembers: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, totalMembers: false }));
    }
    // setIsFieldRequired(initialErrorState);
    return false;
  };

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-1">
          <h1 className="text-3xl font-bold text-highlightColor">Add board of directors composition</h1>
        </div>
        <div>
          <p className="text-sm text-gray-500">Does your organization have a board?</p>
          <fieldset className="mt-4">
            <legend className="sr-only">Board of Directors radio group</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
              {[
                { id: "yes", title: "Yes" },
                { id: "no", title: "No" },
              ].map((option) => (
                <div
                  key={option.id}
                  className="flex items-center">
                  <input
                    id={option.id}
                    name="option"
                    type="radio"
                    value={option.id}
                    onChange={(e) => setHasBoard(e.target.value)}
                    defaultChecked={option.id === "yes"}
                    className="h-4 w-4 border-gray-300 text-highlightColor focus:ring-highlightColor"
                  />
                  <label
                    htmlFor={option.id}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {option.title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="w-full col-span-2">
            <Input
              disabled={hasBoard === "no"}
              type="text"
              autoComplete="new-board-members"
              name="board-members"
              label="Total Number of Board Members"
              value={directorsCompositionDataState?.totalMembers}
              onChange={(e) => {
                checkTotals(e, "totalMembers");
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="relative col-span-2">
            <h4 className="text-xl font-bold text-highlightColor">What is your board makeup?</h4>
          </div>
          <div className="w-full">
            <Input
              disabled={hasBoard === "no"}
              type="text"
              autoComplete="new-female-board-members"
              name="female-board-members"
              label="Number of Female Board Members"
              value={directorsCompositionDataState?.femaleBoardMembers}
              onChange={(e) => {
                checkTotals(e, "femaleBoardMembers");
              }}
            />
          </div>
          <div className="w-full">
            <Input
              disabled={hasBoard === "no"}
              type="text"
              autoComplete="new-board-members-color"
              name="board-members-color"
              label="Number of Board Members of Color"
              value={directorsCompositionDataState?.membersColor}
              onChange={(e) => {
                checkTotals(e, "membersColor");
              }}
            />
          </div>
          <div className="w-full col-span-2">
            <Input
              disabled={hasBoard === "no"}
              type="text"
              autoComplete="new-female-board-members-color"
              name="female-board-members-color"
              label="Number of Female Board Members of Color"
              value={directorsCompositionDataState?.femaleBoardMembersColor}
              onChange={(e) => {
                checkTotals(e, "femaleBoardMembersColor");
              }}
            />
          </div>
        </div>
        <div className="h-5 text-sm text-red-500">{hasIncorrectTotals}</div>
        <div className="flex justify-between border-t border-slate-200 pt-5">
          <div className="mr-auto">
            <Button
              width="w-full"
              onClick={() => setCurrentStep("2")}>
              Back
            </Button>
          </div>
          <div className="ml-auto">
            <Button
              width="w-full"
              disabled={!(hasBoard === "no" || (hasBoard === "yes" && +directorsCompositionDataState?.totalMembers > 0 && !hasIncorrectTotals))}
              onClick={handleOnNext}>
              Next: Terms & Conditions
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationStepThree;
