import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Logo = ({ children, ...props }) => {
  const [isImage, setIsImage] = useState(true)

  return (
    <div className="flex items-center h-[56px] flex-shrink-0">
      {children}
      <div className="transition-all duration-75 min-w-[130px]">
        <Link to="/workspaces">
          {
            props.site?.image_logo && isImage &&
            <img
              className="h-10 sm:h-12 w-auto"
              src={(Object.keys(props?.workspaceDetails).length > 0 && props?.me?.type === "user") ? (props?.workspaceDetails?.image_logo+'?'+Date.now()) : (props.site?.image_logo)}
              alt="Site logo"
              onError={() => setIsImage(false)}
            />
          }
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Logo);
