import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import AuthHeader from "src/components/Auth/AuthHeader";
import ProgressStep from "src/components/Auth/ProgressStep";
import RegistrationStepFour from "src/components/Auth/RegistrationStepFour";
import RegistrationStepOne from "src/components/Auth/RegistrationStepOne";
import RegistrationStepThree from "src/components/Auth/RegistrationStepThree";
import RegistrationStepTwo from "src/components/Auth/RegistrationStepTwo";
import RegistrationCompilation from "src/components/Auth/RegistrationCompilation";
import Terms from "src/components/Auth/Terms";
import Modal from "src/components/Shared/Modal";
import { apiUrl } from "src/config/host";
import { RegistrationContext } from "src/context/RegistrationContext";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";
import ScrollToTop from "src/components/Shared/ScrollToTop";

const initialUserData = {
  firstName: "",
  lastName: "",
  businessRole: "",
  phoneNumber: "",
  email: "",
  verifyEmail: "",
  password: "",
};

const initialRetrievalData = {
  firstName: "",
  lastName: "",
  businessRole: "",
  phoneNumber: "",
  email: "",
  verifyEmail: "",
};

const initialCompanyData = {
  name: "",
  ownership: "",
  size: "",
  industryGroup: "",
  industry: "",
  subIndustryGroup: "",
  companyLogo: "",
};

const initialDirectorsComposition = {
  totalMembers: "",
  femaleBoardMembers: "",
  membersColor: "",
  femaleBoardMembersColor: "",
};

const initialHRISSystem = {
  HRISSystem: "",
  otherHRISSystem: "",
  // apiKey: null,
  // companyDomain: null,
  // userName: null,
  // password: null,
  // APIURL: null,
};

const Registration = () => {
  const location = useLocation();

  const token = new URLSearchParams(location.search).get("token");

  const [isImage, setIsImage] = useState(true);
  const [registrationLoading, setRegistrationLoading] = useState(true);
  const [registrationToken, setRegistrationToken] = useState("");

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAgreementScrolled, setIsAgreementScrolled] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const [currentStep, setCurrentStep] = useState("1");

  const [userData, setUserData] = useState(initialUserData);
  const [retrievalData, setRetrievalData] = useState(initialRetrievalData);
  const [companyData, setCompanyData] = useState(initialCompanyData);
  const [directorsCompositionData, setDirectorsCompositionData] = useState(initialDirectorsComposition);
  const [HRISSystemData, setHRISSystemData] = useState(initialHRISSystem);
  const [isCheckedSameContact, setIsCheckedSameContact] = useState(false);

  const [onSubmitDisable, setOnSubmitDisable] = useState(false);

  const [industryArr, setindustryArr] = useState([]);
  const [industryGroup, setIndustryGroup] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);
  const [subSubIndustry, setSubSubIndustry] = useState([]);
  const [csvDatasetFile, setCsvDatasetFile] = useState(null);

  const [submissionCompleted, setSubmissionCompleted] = useState(false);

  const navigate = useNavigate();

  const steps = [
    {
      _id: 1,
      name: "Contact Info",
      number: "1",
      onClick: () => {
        setCurrentStep("1");
      },
      component: RegistrationStepOne,
    },
    {
      _id: 2,
      name: "Add Organization",
      number: "2",
      onClick: () => {
        setCurrentStep("2");
      },
      component: RegistrationStepTwo,
    },
    {
      _id: 3,
      name: "Add Board",
      number: "3",
      onClick: () => {
        setIsOpenModal(true);
      },
      component: RegistrationStepThree,
    },
    {
      _id: 4,
      name: "Upload Data",
      number: "4",
      onClick: (skip = false) => {
        onSubmit(skip);
      },
      component: RegistrationStepFour,
    },
  ];

  useEffect(() => {
    if (token && registrationLoading) {
      const verifyToken = async () => {
        try {
          setRegistrationLoading(true);

          const res = await fetchData("POST", apiUrl + "/verify-register-token", {
            registerToken: token,
          });
          const resData = await res.json();

          if (resData.status === 200) {
            setRegistrationLoading(false);

            setRegistrationToken(resData.data);
            setUserData((data) => ({ ...data, email: resData.email }));
          } else {
            setTimeout(() => {
              setRegistrationLoading(false);

              toast.error(resData.message);
              navigate("/login");
            }, 1000);
          }
        } catch (err) {
          setTimeout(() => {
            setRegistrationLoading(false);

            toast.error(err.message);
            navigate("/login");
          }, 1000);
        }
      };

      verifyToken();
    } else if (!token) {
      setRegistrationLoading(false);

      toast.error("No token was provided, please try again.");
      navigate("/login");
    }
  }, [navigate, registrationLoading, token]);

  const {
    response: { data: site },
  } = useFetch("/site-settings/unprotected");

  const informationData = [
    {
      _id: 1,
      name: "Results that Drive Change",
      icon: "fa-chart-line",
      description: "Since engaging with Parity.org, 80% of companies have added at least one woman to their executive team, 46% have added at least one woman to their board, and 27% have reached gender parity on their executive team.",
    },
    {
      _id: 2,
      name: "Parity Fuels Innovation",
      icon: "fa-venus-mars",
      description: "Companies that have more women and people of color at the executive and board levels are 19% more innovative than peer organizations lacking in diversity.",
    },
    {
      _id: 3,
      name: "Female Executives are Correlated with Higher Profits",
      icon: "fa-sack-dollar",
      description: "Companies with 30% female leadership see an increase of 15% in profitability compared to those with no female leadership.",
    },
  ];

  function objectToFormData(obj, formData = new FormData(), namespace = "") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = namespace ? `${namespace}[${key}]` : key;
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          if (obj[key] instanceof File) {
            formData.append(propName, obj[key]);
          } else {
            objectToFormData(obj[key], formData, propName);
          }
        } else {
          formData.append(propName, obj[key]);
        }
      }
    }
    return formData;
  }

  const onSubmit = async (skipCsv = false) => {
    try {
      setOnSubmitDisable(true);

      const resp = await fetchData(
        "POST",
        `${apiUrl}/signup`,
        objectToFormData({
          userData: { ...userData, retrievalData },
          companyData,
          directorsCompositionData,
          HRISSystemData,
          termsAccepted: isAgreementChecked ? isAgreementChecked : false,
          csvDatasetFile,
          registerToken: registrationToken,
          skipCsv: typeof skipCsv !== "boolean" ? false : skipCsv,
        }),
        null,
        {},
        "multipart/form-data"
      );

      const respData = resp?.data || {};

      if (respData.status === 200 && respData.message) {
        toast.success(respData.message);
        setSubmissionCompleted(true);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else if (respData?.response?.data || respData.message) {
        toast.error(respData?.response?.data?.message || respData.message);
      } else {
        toast.error("Error while registering user!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setOnSubmitDisable(false);
    }
  };

  if (!token || registrationLoading || !registrationToken) return <></>;

  return (
    <>
      <RegistrationContext.Provider
        value={{
          userDataState: userData,
          setUserDataState: (key, value) =>
            setUserData((data) => ({
              ...data,
              [key]: value,
            })),

          retrievalDataState: retrievalData,
          setRetrievalDataState: (key, value) =>
            setRetrievalData((data) => ({
              ...data,
              [key]: value,
            })),

          companyDataState: companyData,
          setCompanyDataState: (key, value) =>
            setCompanyData((data) => ({
              ...data,
              [key]: value,
            })),

          directorsCompositionDataState: directorsCompositionData,
          setDirectorsCompositionDataState: (key, value) =>
            setDirectorsCompositionData((data) => ({
              ...data,
              [key]: value,
            })),

          HRISSystemDataState: HRISSystemData,
          setHRISSystemDataState: (key, value) =>
            setHRISSystemData((data) => ({
              ...data,
              [key]: value,
            })),

          resetHRISSystemData: () => {
            setHRISSystemData(initialHRISSystem);
          },

          isAgreementCheckedState: isAgreementChecked,
          setIsAgreementCheckedState: (boolVal) => setIsAgreementChecked((prev) => !prev),

          isCheckedSameContactState: isCheckedSameContact,
          setIsCheckedSameContactState: (boolVal) => setIsCheckedSameContact((prev) => !prev),

          industryArrState: industryArr,
          setindustryArrState: (value) => setindustryArr(value),
          industryGroupState: industryGroup,
          setIndustryGroupState: (value) => setIndustryGroup(value),
          industryState: industry,
          setIndustryState: (value) => setIndustry(value),
          subIndustryState: subIndustry,
          setSubIndustryState: (value) => setSubIndustry(value),
          subSubIndustryState: subSubIndustry,
          setSubSubIndustryState: (value) => setSubSubIndustry(value),

          csvDatasetFileState: csvDatasetFile,
          setCsvDatasetFileState: (value) => setCsvDatasetFile(value),

          currentStepState: currentStep,
          onSubmitDisableState: onSubmitDisable,
        }}>
        <div className="relative w-full flex flex-col xl:flex-row xl:flex-wrap">
          <div className="w-full py-12">
            <AuthHeader
              seoTitle={""}
              title="Onboarding - Registration Form"
            />
            <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-3xl">
              <div className="bg-white shadow-md rounded-lg min-h-[183px] overflow-hidden">
                <div className="bg-headerColor p-2">
                  <a href="#!">
                    {site?.image_logo && isImage ? (
                      <img
                        className="mx-auto h-auto max-h-[80px] py-2 w-auto max-w-[80%]"
                        src={`${site?.image_logo}?${Date.now()}`}
                        alt="Logo"
                        onError={() => setIsImage(false)}
                      />
                    ) : (
                      <div className="mx-auto h-auto max-h-[80px] py-2 w-auto max-w-[80%]" />
                    )}
                  </a>
                </div>
                <div className="px-4 py-8 sm:px-10">
                  {!submissionCompleted && (
                    <ProgressStep
                      data={steps}
                      currentStep={currentStep}
                    />
                  )}
                </div>
                <div className="px-4 py-8 sm:px-10">
                  {!submissionCompleted ? (
                    steps.map((item, index) =>
                      item.number === currentStep ? (
                        <item.component
                          key={index}
                          data={item}
                          currentStep={currentStep}
                          setCurrentStep={setCurrentStep}
                          initialRetrievalData={initialRetrievalData}
                          onSubmitDisable={onSubmitDisable}
                        />
                      ) : (
                        ""
                      )
                    )
                  ) : (
                    <>
                      <ScrollToTop />
                      <RegistrationCompilation />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full xl:w-1/4 flex">
            <div className="bg-white py-12 px-20 xl:px-10 w-full xl:pr-40">
              <div className="relative">
                {informationData.length > 0 && (
                  <div className="space-y-8">
                    {informationData.map((item) => (
                      <div
                        className="relative space-y-3 py-5"
                        key={item._id}>
                        {item.icon && (
                          <div className="text-highlightColor text-5xl">
                            <i className={classNames("fa-fw fa-regular", item.icon)}></i>
                          </div>
                        )}
                        {item.name && <div className="text-black text-xl font-black leading-tight">{item.name}</div>}
                        {item.description && <div className="text-slate-500 text-lg leading-snug">{item.description}</div>}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div> */}
          <div className="w-full h-full flex items-end">
            <div className="bg-white py-8 px-20 w-full">
              <div className="flex xl:flex-wrap flex-col items-center justify-between">
                <div className="relative">
                  <div className="text-slate-500 text-sm">
                    <span className="font-semibold">Registration of Trademarks:</span> The names PARiTA® and PARiTAinsight® are trademarks of Parita, Inc.
                  </div>
                </div>
                <div className="relative flex justify-end">
                  <div className="flex divide-x divide-slate-200">
                    <a
                      href="https://www.parita.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="text-slate-500 text-sm hover:underline px-3">
                      Privacy Policy
                    </a>
                    <a
                      href="https://www.parita.com/terms-of-use"
                      target="_blank"
                      rel="noreferrer"
                      className="text-slate-500 text-sm hover:underline px-3">
                      Terms of Use
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Terms and Conditions"
          size="lg"
          defaultOptions={{
            onSuccessButtonText: "Agree",
            onCancelButtonVisible: false,
            onSuccessButtonDisabled: isAgreementScrolled ? false : true,
          }}
          isOpen={isOpenModal}
          onCancel={() => {
            setIsOpenModal(false);
            setIsAgreementChecked(false);
          }}
          onSuccess={() => {
            setIsOpenModal(false);
            setIsAgreementChecked(true);
            setCurrentStep("4");
          }}>
          <Terms
            setIsAgreementScrolled={(e) => setIsAgreementScrolled(e)}
            isAgreementScrolled={isAgreementScrolled}
          />
        </Modal>
      </RegistrationContext.Provider>
    </>
  );
};

export default Registration;
