import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import UploadImage from "src/components/Shared/Forms/Inputs/UploadImage";
import Select from "src/components/Shared/Forms/Selects/Select";
import HrisSystemOne from "src/components/Shared/RegistrationStep/HrisSystemOne";
import HrisSystemTwo from "src/components/Shared/RegistrationStep/HrisSystemTwo.js";
import { baseUrl } from "src/config/host";
import { RegistrationContext } from "src/context/RegistrationContext";

const initialErrorState = {
  HRISSystem: false,
};
const RegistrationStepFour = ({ data, setCurrentStep }) => {
  // const [hrisSystem, setHrisSystem] = useState("");
  // const [otherHRISData, setOtherHRISData] = useState("");
  const [isFieldRequired, setIsFieldRequired] = useState(initialErrorState);
  const [isFinishDisable, setIsFinishDisable] = useState(true);

  const { HRISSystemDataState, setHRISSystemDataState, setIsAgreementCheckedState, resetHRISSystemData, csvDatasetFileState, setCsvDatasetFileState, currentStepState, onSubmitDisableState } = useContext(RegistrationContext);

  const handleHRISDropdown = (e) => {
    if (!e.key > 0) {
      setIsFieldRequired((prev) => ({ ...prev, HRISSystem: true }));
    } else {
      setIsFieldRequired((prev) => ({ ...prev, HRISSystem: false }));
      resetHRISSystemData();
      setHRISSystemDataState("HRISSystem", e);
    }
  };

  const fieldErrorExists = () => {
    if (!HRISSystemDataState?.HRISSystem?.name.length) {
      setIsFieldRequired((prev) => ({ ...prev, HRISSystem: true }));
      return true;
    } else {
      setIsFieldRequired((prev) => ({ ...prev, HRISSystem: false }));
    }
    setIsFieldRequired(initialErrorState);
    return false;
  };

  const handleFinish = (skip = false) => {
    const isError = fieldErrorExists();
    if (!isError) {
      data.onClick(skip);
    }
  };

  useEffect(() => {
    const isDisable = !(currentStepState === "4" && HRISSystemDataState?.HRISSystem?.key && HRISSystemDataState.HRISSystem.key > 0 && HRISSystemDataState.HRISSystem.key !== 3);
    const isCSVDisable = !(currentStepState === "4" && HRISSystemDataState?.HRISSystem?.key && HRISSystemDataState.HRISSystem.key === 3);

    setIsFinishDisable(isDisable && isCSVDisable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HRISSystemDataState.HRISSystem.key, csvDatasetFileState?.lastModified]);

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-1">
          <h1 className="text-3xl font-bold text-highlightColor">Upload your data</h1>
        </div>
        <div>
          <h4 className="text-xl font-bold text-highlightColor">What HRIS system do you use?</h4>
          <div className="text-lg text-black">&#40;Human Resources Information System&#41;</div>
        </div>
        <div className="space-y-3 mt-8">
          <div className="grid grid-cols-2 gap-4 mt-4">
            {/* <br /> */}
            <div className="w-full col-span-2">
              <div>
                <p>
                  <span>
                    <strong>What if I don't see my HRIS System?</strong>
                  </span>{" "}
                  Select 'Other' and we'll provide instructions on how to upload your HR data via our pre-formatted CSV/Template.
                </p>
              </div>
            </div>
            <div className="w-full">
              <Select
                label={"HRIS System"}
                required={isFieldRequired?.HRISSystem}
                options={[
                  { _id: 1, key: 1, name: "BambooHR" },
                  { _id: 2, key: 2, name: "Workday" },
                  { _id: 3, key: 3, name: "Other" },
                ]}
                setSelected={(e) => handleHRISDropdown(e)}
                selected={HRISSystemDataState?.HRISSystem !== "" ? HRISSystemDataState.HRISSystem : { _id: -1, name: "Select HRIS System" }}
              />
              {isFieldRequired?.HRISSystem ? "Field is required" : ""}
            </div>
            <div className={HRISSystemDataState?.HRISSystem?.key !== 3 ? "w-full col-span-2 grid grid-cols-2 gap-4" : "w-full col-span-2 grid gap-x-4"}>
              {HRISSystemDataState?.HRISSystem?.key === 1 ? (
                <>
                  <div className="w-full col-span-2">
                    <strong>Enter your BambooHR API Key and Company Domain</strong>
                    <p>Once validate we will build your dashboard and notify you.</p>
                  </div>
                  <HrisSystemOne />
                </>
              ) : HRISSystemDataState?.HRISSystem?.key === 2 ? (
                <>
                  <div className="w-full col-span-2">
                    <strong>Enter your Workday Username, Password and API URL</strong>
                    <p>Once validate we will build your dashboard and notify you.</p>
                  </div>
                  <HrisSystemTwo />
                </>
              ) : HRISSystemDataState?.HRISSystem?.key === 3 ? (
                <>
                  <div className="w-full col-span-2 flex items-center">
                    <strong>Manually upload a CSV file</strong>{" "}
                    <div className="ml-6 space-y-6 py-3">
                      <div className="flex justify-end">
                        <button
                          onClick={() => (window.location.href = `${baseUrl}/example_format.csv`)}
                          className="flex font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer">
                          <div className="hover:bg-gray-200/20 flex py-2 px-4 rounded items-center gap-x-2">
                            <ArrowDownTrayIcon className="w-6 h-6" /> Use this template
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full col-span-2">
                    <UploadImage
                      fileValue={csvDatasetFileState}
                      onFileChange={setCsvDatasetFileState}
                      maxSizeInKB={250000}
                      accept=".csv"
                      acceptMessage="Accepts .csv with a max file size of 250MB"
                      allowedExtensions={["csv"]}
                      allowedExtensionError="Please select a CSV"
                      maximumSizeError="Please select CSV under size"
                    />
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="w-full">
                        <Input
                          type="text"
                          autoComplete="new-others-hris-system"
                          name="others-hris-system"
                          label="Other HRIS System(if not listed)"
                          value={HRISSystemDataState.otherHRISSystem}
                          onChange={(e) => setHRISSystemDataState("otherHRISSystem", e.target.value)}
                        />
                      </div>  */}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between border-t border-slate-200 pt-5">
          <div className="mr-auto">
            <Button
              width="w-full"
              onClick={() => {
                setCurrentStep("3");
                setIsAgreementCheckedState(false);
              }}
              disabled={onSubmitDisableState}>
              Back
            </Button>
          </div>
          <div className="flex flex-col">
            <div className="flex ml-auto">
              <div className="mr-3">
                <Button
                  width="w-full"
                  onClick={() => handleFinish(true)}
                  disabled={onSubmitDisableState}>
                  Skip Upload
                </Button>
              </div>
              <div>
                <Button
                  width="w-full"
                  onClick={handleFinish}
                  disabled={isFinishDisable || (onSubmitDisableState || !csvDatasetFileState)}>
                  Finish
                </Button>
              </div>
            </div>

            {onSubmitDisableState && csvDatasetFileState !== null && (
              <div className="ml-auto mt-1">
                <span className="text-sm text-slate-400">*We are preparing your file upload, please wait.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationStepFour;
