import { useState } from "react";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DisplayPageForm from "src/components/PageForm/DisplayPageForm";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const WorkspaceGroupAuthorizeUserComponent = ({
  group = {},
  permissions = [],
  workspaceId = "",
  setGroup = () => {},
  pageOptions = [],
  groupChanges = 0,
  setGroupChanges = () => {},
  operatorOptions = [],
  viewOnly = false,
  trustedAttributeArr = [],
  valueType = [],
  workspaceDetails = {},
  addPermission = () => {},
  removePermission = () => {},
  refreshGroups = () => {},
  ...props
}) => {
  const [disableRefresh, setDisableRefresh] = useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }

    const groupPermissions = reorder(permissions, result.source.index, result.destination.index);
    setGroup({ ...group, permissions: [...groupPermissions] });
    setGroupChanges(groupChanges + 1);
  };

  const onRefresh = async (page_id) => {
    setDisableRefresh(true);
    try {
      await refreshGroups(page_id);
    } catch (err) {
      console.dir("err", err);
    } finally {
      setDisableRefresh(false);
    }
  };

  return (
    <>
      {permissions?.length > 0 ? (
        <>
          <div className="space-y-4">
            {permissions?.length > 0 &&
              (authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) ? (
                <>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          className="space-y-4"
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {permissions?.map((permission, index) => {
                            return (
                              <Draggable
                                key={`${group._id}_${index}`}
                                draggableId={`${group._id}_${index}`}
                                index={index}>
                                {(provided, snapshot) => (
                                  <>
                                    <DisplayPageForm
                                      key={`${group._id}_${index}`}
                                      id={`${group._id}_${index}`}
                                      permission={permission}
                                      workspaceId={workspaceId}
                                      groupId={group._id}
                                      setPermission={(e) => {
                                        addPermission(e, index);
                                      }}
                                      pageOptions={pageOptions}
                                      operatorOptions={operatorOptions}
                                      removePermission={(e) => {
                                        removePermission(e, index);
                                      }}
                                      trustedAttributeArr={trustedAttributeArr}
                                      valueType={valueType}
                                      innerRef={provided.innerRef}
                                      draggableProps={provided.draggableProps}
                                      dragHandleProps={provided.dragHandleProps}
                                      workspaceDetails={workspaceDetails}
                                      showRefreshUrls
                                      onRefreshUrl={onRefresh}
                                      disableRefreshUrl={disableRefresh}
                                    />
                                  </>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : (
                permissions?.map((page, index) => {
                  return (
                    <DisplayPageForm
                      key={`${group._id}_${index}`}
                      id={`${group._id}_${index}`}
                      defaultPermission={page}
                      pageOptions={pageOptions}
                      operatorOptions={operatorOptions}
                      viewOnly={viewOnly}
                      trustedAttributeArr={trustedAttributeArr}
                      valueType={valueType}
                      workspaceId={workspaceId}
                      groupId={group._id}
                      workspaceDetails={workspaceDetails}
                    />
                  );
                })
              ))}
          </div>
        </>
      ) : (
        <div>
          <NoData />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupAuthorizeUserComponent);
