import { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Select from "src/components/Shared/Forms/Selects/Select";
import { apiUrl, baseUrl } from "src/config/host";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import UploadImage from "src/components/Shared/Forms/Inputs/UploadImage";
import Section from "src/components/Shared/Containers/Section";
import { classNames } from "src/helpers/classNames";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { fetchData } from "src/async/fetch";
import { objectToFormData } from "src/helpers";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const WorkspaceDataSettings = () => {
  const { id } = useParams();
  const json = {
    HRISSystem: null,
    csvDatasetFile: null,
    apiKey: "",
    companyDomain: "",
    userName: "",
    password: "",
    APIURL: "",
  };
  const [HRISSystem, setHRISSystem] = useState({ _id: -1, name: "Select HRIS System" });
  const [formData, setFormData] = useState(json);
  const [isFinishDisable, setIsFinishDisable] = useState(true);

  const onSubmit = async () => {
    try {
      setIsFinishDisable(true);

      const resp = await fetchData(
        "POST",
        `${apiUrl}/workspaces/${id}/data-settings`,
        objectToFormData({
          ...formData,
        }),
        null,
        {},
        "multipart/form-data"
      );

      const respData = resp?.data || {};

      if (respData.status === 200 && respData.message) {
        toast.success(respData.message);
        setHRISSystem({ _id: -1, name: "Select HRIS System" });
        setFormData(json);
      } else if (respData?.response?.data || respData.message) {
        setIsFinishDisable(false);
        toast.error(respData?.response?.data?.message || respData.message);
      } else {
        setIsFinishDisable(false);
        toast.error("Error while registering user!");
      }
    } catch (error) {
      setIsFinishDisable(false);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
    }
  };

  return (
    <Section className="mb-2">
      <div className="space-y-6">
        <div className="space-y-1">
          <h1 className="text-3xl font-bold text-highlightColor">Data Settings</h1>
        </div>
        <div>
          <h4 className="text-xl font-bold text-highlightColor">What HRIS system do you use?</h4>
          <div className="text-lg text-black">&#40;Human Resources Information System&#41;</div>
        </div>
        <div className="space-y-3 mt-8">
          <div className="mb-2">
            <p>
              <span>
                <strong>What if I don't see my HRIS System?</strong>
              </span>{" "}
            </p>
            <p>Select 'Other' and we'll provide instructions on how to upload your HR data via our pre-formatted CSV/Template.</p>
          </div>
          <div className="space-y-4 mt-4">
            <div className="max-w-md">
              <Select
                label={"HRIS System"}
                options={[
                  { _id: 1, name: "BambooHR" },
                  { _id: 2, name: "Workday" },
                  { _id: 3, name: "Other" },
                ]}
                setSelected={(e) => {
                  setHRISSystem(e);
                  setFormData((prev) => ({
                    ...prev,
                    ...json,
                    HRISSystem: e._id,
                  }));
                  setIsFinishDisable(false);
                }}
                selected={HRISSystem}
              />
            </div>
            {formData.HRISSystem === 1 ? (
              <div className="w-full space-y-2">
                <div className="w-full space-y-2 mb-4">
                  <p>
                    <span>
                      <strong>Enter your HRIS API Key and Company Domain</strong>
                    </span>{" "}
                  </p>
                  <p>Once we validate we will build your dashboards and notify you.</p>
                </div>

                <div className="max-w-md">
                  <Input
                    type="text"
                    autoComplete="new-others-hris-system"
                    name="others-hris-system"
                    label="API Key"
                    value={formData.apiKey}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        apiKey: e.target.value,
                      }));
                      setIsFinishDisable(false);
                    }}
                  />
                </div>

                <div className="max-w-md">
                  <Input
                    type="text"
                    autoComplete="new-others-hris-system"
                    name="others-hris-system"
                    label="Company Domain"
                    value={formData.companyDomain}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        companyDomain: e.target.value,
                      }));
                      setIsFinishDisable(false);
                    }}
                  />
                </div>
              </div>
            ) : formData.HRISSystem === 2 ? (
              <div className="w-full">
                <div className="w-full">
                  <p>
                    <span>
                      <strong>Enter your HRIS Username, Password, and API URL Domain</strong>
                    </span>{" "}
                  </p>
                  <p>Once validate we will build your dashboard and notify you.</p>
                </div>
                <div className="w-full grid gap-y-2 mt-4">
                  <div className="max-w-md">
                    <Input
                      type="text"
                      autoComplete="new-others-hris-system"
                      name="others-hris-system"
                      label="Username"
                      value={formData.userName}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          userName: e.target.value,
                        }));
                        setIsFinishDisable(false);
                      }}
                    />
                  </div>
                  <div className="max-w-md">
                    <Input
                      type="text"
                      autoComplete="new-others-hris-system"
                      name="others-hris-system"
                      label="Password"
                      value={formData.password}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          password: e.target.value,
                        }));
                        setIsFinishDisable(false);
                      }}
                    />
                  </div>
                  <div className="max-w-md">
                    <Input
                      type="text"
                      autoComplete="new-others-hris-system"
                      name="others-hris-system"
                      label="API URL"
                      value={formData.APIURL}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          APIURL: e.target.value,
                        }));
                        setIsFinishDisable(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : formData.HRISSystem === 3 ? (
              <>
                <div className="w-full col-span-2 flex items-center mt-4">
                  <strong>Manually upload a CSV file</strong>{" "}
                  <div className="ml-6 space-y-6 py-3">
                    <div className="flex justify-end">
                      <button
                        onClick={() => (window.location.href = `${baseUrl}/example_format.csv`)}
                        className="flex font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer">
                        <div className="hover:bg-gray-200/20 flex py-2 px-4 rounded items-center gap-x-2">
                          <ArrowDownTrayIcon className="w-6 h-6" /> Use this template
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full col-span-2">
                  <UploadImage
                    fileValue={formData.csvDatasetFile}
                    onFileChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        csvDatasetFile: e,
                      }));
                      setIsFinishDisable(false);
                    }}
                    maxSizeInKB={250000}
                    accept=".csv"
                    acceptMessage="Accepts .csv with a max file size of 250MB"
                    allowedExtensions={["csv"]}
                    allowedExtensionError="Please select a CSV"
                    maximumSizeError="Please select CSV under size"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={classNames("w-full flex justify-end gap-x-4 mt-20 sm:mt-4 transition-all duration-150 pt-4 opacity-100")}>
          <Button
            version="gray"
            onClick={() => {
              setHRISSystem({ _id: -1, name: "Select HRIS System" });
              setFormData(json);
              setIsFinishDisable(true);
            }}
            disabled={isFinishDisable}>
            Undo changes
          </Button>
          <Button
            onClick={onSubmit}
            disabled={isFinishDisable}>
            Update
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default WorkspaceDataSettings;
