import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Button from "src/components/Shared/Buttons/Button";
import StylesPreview from "src/components/Shared/Styles/StylesPreview";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const StylesBuilder = ({ data = {}, layoutType, handleBrowserTabText = null, onSubmit = () => { }, title = "", secondaryTitle = "", headerContent = null, setWorkspace = () => { }, propsButtonOption = {}, setButtonIsDisabled = () => {}, updatedSite = {}, setUpdatedSite = () => {}, getSiteData = () => {}, handleStylesDataChange = () => {}, ...props }) => {
  const { id } = useParams();

  const onClear = () => {
    setButtonIsDisabled(true);
    setUpdatedSite(getSiteData());
  };

  return (
    <div className="max-w-7xl grid space-y-8">
      <StylesPreview
        layoutType={layoutType}
        handleBrowserTabText={handleBrowserTabText}
        menu={["Home", "About Us", "Feedback"]}
        styles={updatedSite}
        user={props.user}
        setWorkspace={setWorkspace}
        onChange={handleStylesDataChange}
        workspaceDetails={data}
      />
      <div className="border-t border-gray-200 mt-5 pt-3 w-full flex justify-end gap-x-4">
        <Button
          version="gray"
          disabled={propsButtonOption?.buttonLoader}
          onClick={onClear}>
          Undo changes
        </Button>
        {(!propsButtonOption?.buttonLoader) ? authorizeUserComponentAccess(props.user, id, "layout", ["update"]) && <Button disabled={propsButtonOption?.buttonIsDisabled} onClick={() => onSubmit(updatedSite)}>Update
        </Button> : (
          <Button disabled={propsButtonOption?.buttonLoader}>
            Update<ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" />
          </Button>
        )}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(StylesBuilder);
