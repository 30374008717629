import React from "react";
import { classNames } from "src/helpers/classNames";
import { CheckIcon } from "@heroicons/react/24/solid";

const ProgressStep = ({data, currentStep}) => {
  const length = data.length;
  return (
    <>
      <div className="relative mx-auto">
        {data?.length > 0 &&
          <>
            <div className="absolute top-4 left-1/2 -translate-x-1/2 h-2 bg-slate-100 overflow-hidden w-3/4">
              <div className="bg-highlightColor h-full rounded-full transition-all duration-300" style={{ width: (((currentStep-1)/(length-1))*100)+"%" }}></div>
            </div>
            <div className="relative z-[1] flex items-center justify-between gap-8">
              {data.map((item, index) => (
              <div 
                key={item._id}
                className="flex items-center justify-center flex-col w-full"
              >
                <div className={classNames(
                  "w-10 h-10 flex items-center justify-center rounded-full text-lg font-medium text-white transition-all duration-300",
                  (currentStep <= index)?"bg-slate-300":"bg-highlightColor"
                )}>{(currentStep <= (index+1))?item.number:<CheckIcon className="h-6 w-6"/>}</div>
                <div className={classNames(
                  "text-lg font-semibold transition-all duration-300", 
                  (currentStep <= index)?"text-slate-300":"text-slate-800"
                )}>{item.name}</div>
              </div>
              ))}
            </div>
          </>
        }
      </div>
    </>
  );
};

export default ProgressStep;
