import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { deleteWorkspace } from "src/actions/workspace";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import Modal from "src/components/Shared/Modal";
import { H3, H4 } from "src/components/Shared/Text/Headers";
import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";
import { manageWorkspaceSettings } from "src/actions/workspace";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import { apiUrl } from "src/config/host";
import WorkspaceDataSettings from "./WorkspaceDataSettings";
import { authorizeUserComponentAccess, authorizeUserComponentAccessDuplicate } from "src/helpers/authorizeUserComponentAccess";

const WorkspaceSettings = ({ user, workspaceId, ...props }) => {
  const [previousContactEmail, setPreviousContactEmail] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactDisabled, setContactDisabled] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [autoImportDomoUser, setAutoImportDomoUser] = useState(false);
  const navigate = useNavigate();

  const [deleteIsLiading, setDeleteIsLiading] = useState(false);

  useEffect(() => {
    setAutoImportDomoUser(props.workspaceDetails?.auto_import_domo_user);
  }, [props.workspaceDetails?.auto_import_domo_user]);

  const handleUpdate = async (autoImportDomoUser) => {
    try {
      await props.manageWorkspaceSettings({ workspace_id: workspaceId, auto_import_domo_user: autoImportDomoUser });
    } catch (error) {
    } finally {
    }
  };

  const isEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  };

  const sendContactEmail = async (previous = false) => {
    try {
      setContactDisabled(true);

      const email = previous && typeof previous === "boolean" ? previousContactEmail : contactEmail;

      const res = await fetchData("POST", `${apiUrl}/workspaces/${workspaceId}/sendContactEmail`, { email });
      const resData = await res.json();

      if (resData.status === 200) {
        setContactEmail("");
        setPreviousContactEmail(email);
        toast.success(resData.message);
      } else {
        toast.error(resData.message);
      }

      setContactDisabled(false);
    } catch (err) {
      setContactDisabled(false);
      toast.error(err.message);
    }
  };

  return (
    <>
      {user?.type === "admin" && (
        <Section className="mb-2">
          <div className="grid gap-y-10">
            <div className="w-full">
              <H3 caption="Once you have added in a new set of Domo Everywhere credentials you can invite someone to set up this workspace.">Workspace Setup Invites</H3>
            </div>
            <div className="w-full flex items-end gap-2 content-center px-1">
              <div className="flex flex-col">
                <p className="">Contact Email</p>
                <div className="w-[500px]">
                  <Input
                    type="text"
                    value={contactEmail}
                    placeholder="Contact Email"
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </div>
              </div>
              <Button
                disabled={contactDisabled || !contactEmail || !isEmail(contactEmail)}
                version="primary"
                className="!text-red-300 !border-red-300"
                onClick={() => sendContactEmail()}>
                Send Email
              </Button>

              {previousContactEmail.length > 0 && (
                <Button
                  disabled={!previousContactEmail || contactDisabled}
                  version="primary"
                  className="!text-red-300 !border-red-300 !h-[34px] !w-[34px] !p-0 !botder"
                  onClick={() => sendContactEmail(true)}>
                  <i className="fa-solid fa-rotate-right"></i>
                </Button>
              )}
            </div>
          </div>
        </Section>
      )}
      {authorizeUserComponentAccessDuplicate(user, workspaceId, "integration", ["update"]) &&
      <WorkspaceDataSettings />
      }
      {authorizeUserComponentAccess(user, workspaceId) && (
        <Section>
          <div className="grid gap-y-10">
            <div className="w-full grid gap-y-4">
              <H3 caption="Manage the workspace's is_global filters and credentials from one location.">DOMO Integration</H3>
              <WorkspaceIntegrationsList
                workspaceId={workspaceId}
                workspaceDetails={props.workspaceDetails}
              />
              {props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
                <TextAccordion
                  headerTextPosition="left"
                  headerText="Advanced details">
                  <div className="grid gap-y-6 py-3">
                    <div className="flex md:flex-row flex-col gap-y-3 justify-between">
                      <H4 caption="Automatically imports Domo for users that need to be added to the workspace.">Auto import DOMO users</H4>
                      <div className="ml-2">
                        <Toggle
                          checked={autoImportDomoUser}
                          onChange={(e) => {
                            setAutoImportDomoUser(e);
                            handleUpdate(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </TextAccordion>
              )}
            </div>
            <hr className="w-full" />
            <div className="w-full flex items-center">
              <H3 caption="To delete a workspace, make sure you move all resources within it.">Delete workspace</H3>
              <Button
                version="secondary"
                className="h-12 !text-red-300 !border-red-300"
                onClick={() => setDeleteId(workspaceId)}>
                Delete
              </Button>
            </div>
          </div>
        </Section>
      )}

      <Modal
        title="Workspace"
        secondaryTitle="Delete"
        isOpen={deleteId ? true : false}
        onCancel={() => setDeleteId(null)}
        isLoading={deleteIsLiading}
        onSuccess={async () => {
          setDeleteIsLiading(true);
          await props.deleteWorkspace(deleteId);
          navigate("/workspaces");
          setDeleteIsLiading(false);
        }}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="px-3 py-6 flex flex-col whitespace-nowrap gap-y-1 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this workspace?
          </p>
          <div className="text-gray-400">Once you delete this workspace it's gone for good.</div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { deleteWorkspace, manageWorkspaceSettings })(WorkspaceSettings);
