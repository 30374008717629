import { XMarkIcon } from "@heroicons/react/20/solid";
import { CloudArrowDownIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "src/components/Shared/Buttons/Button";
import { convertToBase64 } from "src/helpers/image";

const UploadImage = ({ 
  fileValue = null, 
  onFileChange = () => {}, 
  onBinaryFileChnage = () => {}, 
  maxSizeInKB = 100000, 
  accept="image/*",
  acceptMessage="Accepts .png & .jpg with a max file size of 2MB",
  allowedExtensions = ["png", "jpg", "jpeg", "avif", "svg", "svg+xml"],
  allowedExtensionError = 'Please select an image',
  maximumSizeError = 'Please select image under size',
  Footer = <></>
}) => {
  const [file, setFile] = useState(null);
  const [fileBlobValue, setFileBlobValue] = useState(fileValue ? URL.createObjectURL(fileValue) : null);
  const [fileName, setFileName] = useState(fileValue?.name || "");

  const handleFileChange = (e) => {
    if (e.target?.files.length && allowedExtensions.includes(e.target?.files[0].type.split("/")[1])) {
      // setFileBlobValue(URL.createObjectURL(e.target.files[0]));
      if (e.target.files[0]?.name) {
        setFileName(e.target.files[0]?.name);
        convertToBase64(e.target.files[0], setFile);
        onFileChange(e.target.files[0]);
      } 
      
      // else {
      //   setFileName("imageFile");
      // }
    }
  };

  const handleClose = () => {
    onFileChange(null);
  };

  useEffect(() => {
    onBinaryFileChnage(file);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    setFileBlobValue(fileValue ? URL.createObjectURL(fileValue) : null);
    setFileName(fileValue?.name || "");
  }, [fileValue]);

  const handleImageFile = (e) => {
    if (e.target.files.length && !allowedExtensions.includes(e.target.files[0]?.type.split("/")[1])) {
      return toast.error(allowedExtensionError);
    }
    if (e.target.files[0] && e.target.files[0]?.size && e.target.files[0]?.size > maxSizeInKB * 1000) {
      return toast.error(`${maximumSizeError} ${maxSizeInKB / 1000} MB`);
    } else {
      handleFileChange(e);
    }
  };

  return (
    <div className="mt-5">
      <div className="w-full grid gap-5">
        <div className="relative">
          {fileBlobValue && accept === 'image/*' && (
            <>
              <div className="flex flex-wrap py-5 -mx-5">
                <div className="w-40 h-28 relative m-5">
                  <button
                    type="button"
                    className="h-8 w-8 rounded-full shadow absolute -top-4 -right-2 flex items-center justify-center bg-white"
                    onClick={() => handleClose()}>
                    <XMarkIcon className="h-5 w-5 text-red-500" />
                  </button>
                  <div className="w-full h-full overflow-hidden rounded-lg">
                    <img
                      src={fileBlobValue}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="max-w-3xl relative border-2 border-dashed border-slate-300 rounded-3xl p-5 overflow-hidden flex flex-col justify-center items-center">
            <input
              type="file"
              onChange={(e) => {
                handleImageFile(e);
                e.target.value = null;
              }}
              accept={accept}
              className="absolute top-0 right-0 h-[600px] w-[600px] z-10 opacity-0 cursor-pointer"
            />
            {fileName ? (
              <div className="font-medium flex focus:outline-none text-gray-400 border-leftNavColor cursor-pointer sm:w-auto">
                <div className="flex items-center gap-x-2">
                  <DocumentTextIcon className="w-6 h-6" /> {fileName ? fileName : "No file chosen."}
                </div>
              </div>
            ) : (
              <div className="h-full w-full p-5 flex flex-col justify-center items-center relative">
                <div className="flex flex-col justify-center items-center space-y-4">
                  <CloudArrowDownIcon className="w-12 h-12 text-gray-400" />
                  <div className="text-gray-400 font-semibold">Drag & Drop file here</div>
                  <div className="h-[1px] bg-slate-200 w-full flex justify-center items-center !my-8">
                    <span className="text-gray-700 flex justify-center items-center w-8 h-8 rounded-full bg-slate-50 border border-slate-900/10 text-xs font-semibold uppercase">or</span>
                  </div>
                </div>
                <label htmlFor="bulk_upload-input">
                  <Button
                    version="secondary"
                    onClick={() => {}}>
                    Select file
                  </Button>
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="bulk_upload-input"
                  name="bulk_upload-input"
                  accept=".csv"
                  className="hidden"
                />
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <div className="text-sm text-slate-500 w-full undefined">
              { acceptMessage }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
