const RegistrationCompilation = () => {
  return (
    <div className="space-y-6">
      <div className="space-y-1">
        <h1 className="text-3xl text-center font-bold text-black">
          Let us take it from here!
        </h1>
      </div>
      <div className="text-center">
        <div className="text-lg text-black">
          Thank you for completing your registration. We will send you an email when your dashboard has been set up and include instructions for accessing it. This may take up to 2 business days.
        </div>
      </div>
    </div>
  );
};

export default RegistrationCompilation;
